<template>
  <base-material-card
    color="primary"
  >
    <template v-slot:heading>
      <div class="display-1 font-weight-light">
        {{ vesselClass.name }} Vessels
      </div>
      <div class="subtitle-1">
        {{ vesselClass.company_name }}
      </div>
    </template>

    <v-data-table
      :headers="headers"
      :items="vessels"
      :options.sync="options"
      :loading="loading"
      hide-default-footer
      class="mt-5"
    >
      <template v-slot:item="vessel">
        <tr>
          <td>
            <router-link
              class="table-link"
              :to="'/vessels/' + vessel.item.id"
            >
              {{ vessel.item.name }}
            </router-link>
          </td>
          <td>
            <span>{{ vessel.item.imo }}</span>
          </td>
          <td>
            <span>{{ vessel.item.official_number }}</span>
          </td>
          <td>
            <v-speed-dial
              v-model="fab[vessel.item.id]"
              direction="left"
            >
              <template v-slot:activator>
                <v-btn
                  v-model="fab[vessel.item.id]"
                  fab
                  small
                  color="primary"
                >
                  <v-icon>
                    {{ fab[vessel.item.id] ? 'mdi-close' : 'mdi-chevron-left' }}
                  </v-icon>
                </v-btn>
              </template>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      fab
                      x-small
                      color="error"
                      @click="removeVessel(vessel.item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Remove</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <router-link
                      class="table-link"
                      :to="'/vessels/' + vessel.item.id"
                    >
                      <v-btn
                        fab
                        x-small
                        color="success"
                      >
                        <v-icon>mdi-eye-check</v-icon>
                      </v-btn>
                    </router-link>
                  </span>
                </template>
                <span>View</span>
              </v-tooltip>
            </v-speed-dial>
          </td>
        </tr>
      </template>
    </v-data-table>

    <table-footer
      :options="options"
      :total="total"
    />
  </base-material-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import axios from 'axios'
  import { checkCUD } from '@/shared/management'

  export default {
    components: {
      TableFooter: () => import('../../components/TableFooter'),
    },
    data: () => ({
      headers: [
        { text: 'Vessel Name', value: 'name' },
        { text: 'IMO', value: 'imo' },
        { text: 'Official #', value: 'official_number' },
        { text: 'Actions', value: '', sortable: false },
      ],
      vessels: [],
      options: {},
      total: 0,
      loading: false,
      fab: {},
      vesselClass: {},
    }),

    computed: {
      ...mapState({
        role: state => state.authentication.role,
      }),
    },

    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },

    mounted () {
      this.getDataFromApi()
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getDataFromApi () {
        this.loading = true
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          let apiurl = `vessel-class/vessel/${this.$route.params.id}?page=${page}&per_page=${itemsPerPage}`
          if (sortBy[0]) {
            const direction = sortDesc[0] ? 'desc' : 'asc'
            apiurl += `&direction=${direction}&sortBy=${sortBy[0]}`
          }
          const response = await axios.get(apiurl)
          this.vesselClass = response.data.vessel_class[0]
          this.vessels = response.data.vessels.data
          this.total = response.data.vessels.total
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },

      async removeVessel (id) {
        if (!checkCUD(this.role.id)) {
          this.showSnackBar({ text: 'This action is not permitted.', color: 'warning' })
          return
        }
        try {
          const response = await axios.post('vessels/update-vessel-class/' + id)
          this.showSnackBar({ text: response.data.message, color: 'success' })
          this.getDataFromApi()
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
      },
    },
  }
</script>
